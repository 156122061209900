<template>
  <div class="content v-box" style="flex-wrap: wrap;" >

    <div style="width: 100%; margin-top: 0px; height: auto; min-height: 600px;" class="table-box" ref="tableBox">
      <el-table :data="adviceList" style="width: 100%;" >
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="100" />
        <el-table-column prop="phone" label="联系方式" width="150" />
        <el-table-column prop="sex" label="性别" width="80" />
        <el-table-column prop="createTime" label="时间" width="155" >
          <template #default="scope">
            <p>{{ myFormatDate(scope.row.createTime) }}</p>
          </template>
        </el-table-column>

        <el-table-column prop="desc" label="建议" >
          <template #default="scope">
            <p>{{ scope.row.desc }}</p>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="100">
          <template #default="scope">
            <el-button
                v-show="scope.row.imageUrlList && scope.row.imageUrlList.length > 0"
                link
                type="primary"
                @click="itemClick(scope.row)"
                size="small">
              查看图片
            </el-button>
            <p v-show="!scope.row.imageUrlList || scope.row.imageUrlList.length < 1">无图片</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination" style="width: 100%; margin-left: 8px; margin-right: 8px">
      <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[8, 12, 20, 40, 100]"
          :current-page="pageNo"
          :page-size="pageSize"
          :total="count"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>

    <el-image-viewer
        v-if="showViewer"
        @close="closeViewer"
        @switch="swith"
        :infinite="false"
        :initial-index="imageIndex"
        :url-list="viewerList" />
  </div>
</template>

<script>
import {formatDate} from '../js/formatDate.js';

export default {
  name: "AdviceList",
  mounted(){
    this.getList();
  },

  data(){
    return{
      adviceList:[],
      currentSrc:'',
      showViewer: false,  //大图
      viewerList:[],
      idList:[],
      imageIndex:0,
      pageNo: 1,
      pageSize: 12,
      count:0,
    }
  },
  watch: {
    $route(newV, oldV) {
      this.id = newV.params.id;
      this.getList();
    },

  },
  methods:{
    myFormatDate(time) {
      let date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    },
    itemClick(row){
      let arr = row.imageUrlList ? row.imageUrlList.split(",") : [];

      console.log(row, row.imageUrlList, arr)

       let list = [];
      this.idList=[];
      for (let item of arr) {
        list.push('http://yuncangai.com/iotapi/ly/image/get/'+item)
        this.idList.push(item);
      }
      this.viewerList = list;

      this.currentSrc = list && list.length > 0 ? list[0] : '';
      this.imageIndex = 0
      this.showViewer = true

    },
    getList(){
      this.apiUtils.advicePage({pageSize:this.pageSize, pageNo:this.pageNo }).then(res => {
        if (res.code == 200){
          console.log(res.data);
          this.adviceList = res.data.list
          this.count = res.data.count
          //   this.pageSize = res.pageSize;
          //  this.pageNo = res.pageNo
        } else{
          this.$message.error("获取列表失败! " + res.message);
          console.log(res);
        }

      });
    },
    swith(index){
      console.error(index, this.idList)

    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function (size) {
      this.pageSize = size;
      this.getList();
    },
    // 分页导航
    handlePageChange(val) {
      this.pageNo = val
      this.getList();
    },
    closeViewer() {
      console.error("..............................")
      this.showViewer = false

    },
  }
}
</script>

<style scoped>
/deep/ .is-disabled{
  visibility: hidden;
}
/deep/ .el-image__error, /deep/ .el-image__inner, /deep/ .el-image__placeholder{
  width: auto;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}
</style>